import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import DeviceImg from "../../assets/images/devices.png";
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';
import MeasurementList from "../Dashboard/components/MeasurementList";

//mui-components
import {
  Home
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  MenuItem,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//externals
import { Icon } from '@iconify/react';
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'react-image-gallery/styles/css/image-gallery.css';
import secureLocalStorage from "react-secure-storage";

//services
import ApiCalls from "../../services/try";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" sx={{ p: 3 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

// ----------------------------------------------------------------------

const PatientView = () => {

  const [tabValue, setTabValue] = useState(0);

  const [filterOptions, setFilterOptions] = useState([
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'week',
      label: 'One Week',
    },
    {
      value: 'month',
      label: 'One Month',
    },
    {
      value: 'all',
      label: 'All',
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);

  const [patientId, setPatientId] = useState('');
  const [paramName, setParamName] = useState('');

  const [selectedDateFilter, setSelectedDateFilter] = useState(filterOptions[0]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [physicianDetails, setPhysicianDetails] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [measureParameters, setMeasureParameters] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [images, setImages] = useState([]);
  const [patientHistory, setPatientHistory] = useState([]);

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);

  const [loadPatientDetails, setLoadPatientDetails] = useState(true);

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const loadPatientDetailsById = (patientId) => {
    setLoadPatientDetails(true);
    ApiCalls.getdetails("getpatient/" + patientId + "/patients")
      .then((response) => {
        console.log(response.data);
        if (response.data.patient != undefined) {
          setPatientDetails(response.data.patient[0]);
          setLoadPatientDetails(false);
        } else {
          setLoadPatientDetails(false);
          setPatientDetails([]);
        }
      })
  }

  const loadPhysicianDetailsById = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getPhysician")
      .then((response) => {
        console.log(response.data.patient);
        if (response.data.patient !== undefined) {
          setPhysicianDetails(response.data.patient)
        } else {
          setPhysicianDetails([]);
        }
      })
  }
  const loadDeviceDetailsById = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getdevices")
      .then((response) => {
        console.log(response.data.device);
        if (response.data.device !== undefined) {
          setDeviceDetails(response.data.device)
        } else {
          setDeviceDetails([])
        }
      })
  }
  const loadMeasurementDetailsById = (patientId) => {
    const paramName = "No parameter";

    ApiCalls.getdetails("patient/" + patientId + "/parameter/" + paramName + "/getmeasurementdetails")
      .then((response) => {
        if (response.data.measurement !== undefined) {
          setMeasurements(response.data.measurement);
        } else {
          setMeasurements([]);
        }
      })
      .catch((error) => {
        setMeasurements([]);
      })
  }

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  }

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  }

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    chartMeasurements(patientId, selectedParamFilter.label, filter.value)
    handleCloseDateFilters();
  }

  const handleParamFilterChange = (filter) => {
    setSelectedParamFilter(filter);
    chartMeasurements(patientId, filter.label, selectedDateFilter.value)
    handleCloseParamFilters();
  }


  const chartMeasurements = async (patientId, parameterName, dateFilter) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    let labelData = [];
    let chartData = [];

    try {
      const res = await ApiCalls.getdetails(`patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/0`);

      if (!res.data.measurement) {
        chartData = [];
      } else {
        for (const dataObj of res.data.measurement) {
          labelData.push(moment.utc(Date.parse(dataObj.dateOfReading)).format('MMM-DD hh:mm A'));
          chartData.push(dataObj.readingValues);
        }
      }
    } catch (err) {
      console.error(err);
      chartData = [];
      labelData = [];
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(canvas, {
      type: 'line',
      data: {
        labels: labelData.length > 0 ? labelData : ['No data available'],
        datasets: chartData.length > 0 ? [
          {
            label: parameterName + ' () Reading Level',
            data: chartData,
            borderColor: '#76B0F1',
            backgroundColor: 'RGBA(118, 176, 241, 0.5)',
            fill: true,
            borderWidth: 0.5,
            tension: 0.5,
          },
        ] : [
          {
            label: parameterName + ' () Reading Level',
            data: [0],
            borderColor: '#76B0F1',
            backgroundColor: 'RGBA(118, 176, 241, 0.5)',
            fill: true,
            borderWidth: 0.5,
            tension: 0.5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const getParameterName = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
      .then((res) => {
        console.log(res.data);
        if (res.data.measure !== undefined) {
          setParamName(res.data.measure[0].paramName)
          const options = res.data.measure.map(d => ({
            "label": d.paramName,
            "value": d.paramid
          }))
          var dateFilter = 'today';
          chartMeasurements(patientId, res.data.measure[0].paramName, dateFilter);
          setParameters(options);
          setSelectedParamFilter(options[0])
        } else {
          setParamName('');
          setParameters([]);
        }
      })
  }

  const loadMeasurementParameters = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
      .then((res) => {
        console.log(res.data);
        if (res.data.measure !== undefined) {
          setMeasureParameters(res.data.measure[0].paramName)
        }
      })
  }

  const loadPatientHistory = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/bedById")
      .then((response) => {
        console.log(response.data);
        if (response.data.beds != undefined) {
          console.log(response.data.beds);
          setPatientHistory(response.data.beds);
        } else {
          setPatientHistory([]);
        }
      }).catch((err) => {
        console.log(err);
      })
  }


  useEffect(() => {
    const patientid = secureLocalStorage.getItem("PatientToView")
    setPatientId(patientid);
    loadPatientDetailsById(patientid);
    loadPhysicianDetailsById(patientid);
    loadDeviceDetailsById(patientid);
    getParameterName(patientid);
    loadMeasurementParameters(patientid);
    loadMeasurementDetailsById(patientid);
    fetchImages(patientid);
    loadPatientHistory(patientid);
  }, [])

  const fetchImages = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/downloadFilesByPatientId")
      .then((response) => {
        console.log(response.data);
        if (response.data != undefined) {
          setImages(response.data);
        }
      })
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container sx={{ marginTop: 10 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard">
              <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                Home
              </Button>
            </Link>

            <Link to="/managepatient">
              <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                {"Manage " + secureLocalStorage.getItem("PatientName")}
              </Button>
            </Link>

            <StyledBreadcrumb label={secureLocalStorage.getItem("PatientName") + " View"} />
          </Breadcrumbs>
        </Stack>

        <Card>
          <CardHeader
            title={<Tabs
              value={tabValue}
              onChange={handleChangeTab}
              onMouseDown={handleMouseDown}
              aria-label="icon label tabs example"
            >
              <Tab
                icon={<Icon icon={'solar:user-id-bold'} width="30" />}
                label={"General Details"}
                sx={{
                  fontSize: 18,
                }}
              />
              <Tab
                icon={<Icon icon={'fa6-solid:user-doctor'} width="30" />}
                label={secureLocalStorage.getItem("PhysicianName") + " Details"}
                wrapped
                sx={{
                  fontSize: 18,
                }}
              />
              <Tab
                icon={<Icon icon={'tabler:devices-pin'} width="30" />}
                label={secureLocalStorage.getItem("DeviceName") + " Details"}
                sx={{
                  fontSize: 18,
                }}
              />
              <Tab
                icon={<Icon icon={'fa-solid:chart-bar'} width="30" />}
                label={"Measurement Details"}
                wrapped
                sx={{
                  fontSize: 18,
                }}
              />
              <Tab
                icon={<Icon icon={'fluent:history-16-filled'} width="30" />}
                label={"History"}
                wrapped
                sx={{
                  fontSize: 18,
                }}
              />
            </Tabs>}
          />
          <CardContent>
            <TabPanel value={tabValue} index={0}>
              {loadPatientDetails ? ( // Display loading component when loadPatientDetails is true
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={200}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <Box sx={{ mb: 2 }}>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Skeleton variant="text" width="50%" />
                        <Skeleton variant="text" width="70%" />
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>

              ) : (
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4} >
                      <Avatar
                        variant='rounded'
                        alt={patientDetails.firstName + ' ' + patientDetails.lastName}
                        src={patientDetails.image}
                        sx={{ width: 200, height: 200, objectFit: "contain" }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} >
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5" sx={{ color: 'text.primary' }}>
                          {patientDetails.firstName + " " + patientDetails.lastName}
                        </Typography>

                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          {patientDetails.email}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Gender
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {patientDetails.gender}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          DOB
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {patientDetails.dob}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {patientDetails.phone}
                          </Typography>
                        </Box>

                        <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                            Location
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {/* {JSON.stringify(patientDetails.address) === '{}' || patientDetails.address.city === " " ? "Not provided " : patientDetails.address.city + " , " + patientDetails.address.state} */}
                          </Typography>
                        </Box>
                      </Box>

                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {
                physicianDetails.length > 0 ? <Grid container spacing={3}>
                  {physicianDetails.map((physician) => (
                    <Grid item xs={12} sm={6} md={6} >
                      <Card key={physician.physicianId} sx={{
                        boxShadow: 3,
                        px: 2,
                        py: 3,
                        minHeight: 200,
                        backgroundColor: "#F4F6F8",
                      }}>
                        <CardHeader
                          avatar={
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <Avatar
                                variant='rounded'
                                alt={physician.firstName + ' ' + physician.lastName}
                                src={physician.image}
                                sx={{ width: 120, height: 120, objectFit: "contain" }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Name :
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.firstName + ' ' + physician.lastName}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Email
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.email}
                                  </Typography>
                                </Box>
                                <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                    Phone
                                  </Typography>
                                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {physician.phone}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid> :
                  <center>
                    No {secureLocalStorage.getItem("PhysicianName")} Assigned
                  </center>
              }
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {
                deviceDetails.length > 0 ?
                  <Grid container spacing={3}>
                    {deviceDetails.map((device) => (
                      <Grid item xs={12} sm={6} md={6} >
                        <Card key={device.deviceId} sx={{
                          boxShadow: 3,
                          px: 2,
                          py: 3,
                          minHeight: 200,
                          backgroundColor: "#F4F6F8",
                        }}>
                          <CardHeader
                            avatar={
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                                <Avatar
                                  variant='rounded'
                                  alt={device.devicename}
                                  src={device.deviceimageName ? device.deviceimageName : DeviceImg}
                                  sx={{ width: 120, height: 120, objectFit: "contain" }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                  <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                      Device Name
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                      {device.devicename}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                      Device Macid
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                      {device.decmacid}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                      Model Name
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                      {device.modelName === undefined ? " " : device.modelName}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            }
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid> :
                  <center>
                    No {secureLocalStorage.getItem("DeviceName")} Assigned
                  </center>
              }
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Grid item xs={12} sm={12} md={12} >
                <Card
                  sx={{
                    px: 5,
                    boxShadow: 5,
                    textAlign: 'left',
                  }}
                >
                  <CardHeader title="Graphical Display" action={
                    <>
                      <Tooltip title="Filter Parameter">
                        <Button color='primary' variant='contained' onClick={handleOpenParamFilters} endIcon={<Iconify icon="material-symbols:filter-alt" />}>
                          {selectedParamFilter.label}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Filter Date">
                        <Button className='ml-2' color='primary' variant='contained' onClick={handleOpenDateFilters} endIcon={<Iconify icon="material-symbols:filter-list-rounded" />}>
                          {selectedDateFilter.label}
                        </Button>
                      </Tooltip>
                    </>
                  } />
                  <CardContent>
                    <Box sx={{ p: 3, pb: 1, height: 350, borderRadius: "10px" }} dir="ltr" style={{ backgroundColor: '#27293d' }}>
                      <canvas ref={canvasRef} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Popover
                open={Boolean(openDateFilter)}
                anchorEl={openDateFilter}
                onClose={handleCloseDateFilters}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack spacing={0.75}>
                  {filterOptions.map((option) => (
                    <MenuItem key={option.value} selected={option.value === selectedDateFilter.value} onClick={() => handleDateFilterChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              </Popover>

              <Popover
                open={Boolean(openParamFilter)}
                anchorEl={openParamFilter}
                onClose={handleCloseParamFilters}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <Stack spacing={0.75}>
                  {parameters.map((option) => (
                    <MenuItem key={option.value} selected={option.value === selectedParamFilter.value} onClick={() => handleParamFilterChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Stack>
              </Popover>

              <Grid item xs={12} sm={12} md={12} sx={{
                mt: 2
              }} >
                <MeasurementList
                  title="Measurement List"
                  measurements={measurements}
                  setMeasurements={setMeasurements}
                  patientId={patientId}
                />
              </Grid>

            </TabPanel>

            <TabPanel value={tabValue} index={4}>
              {patientHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Ward No</TableCell>
                        <TableCell>Bed No</TableCell>
                        <TableCell>Admitted On</TableCell>
                        <TableCell>Discharged On</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientHistory.map((patient) => (
                        <TableRow key={`${patient.bedId}-${patient.mapStatus}`}>
                          <TableCell>
                            {patient.mapStatus === 'Admitted' ? (
                              <Avatar sx={{ bgcolor: (theme) => theme.palette["success"].main }}>
                                <Icon icon={'material-symbols:inpatient-rounded'} />
                              </Avatar>
                            ) : (
                              <Avatar sx={{ bgcolor: (theme) => theme.palette["error"].main }}>
                                <Icon icon={'material-symbols:outpatient-rounded'} />
                              </Avatar>
                            )}
                            <Typography variant="body2" sx={{ marginTop: 1, color: patient.mapStatus === 'Admitted' ? (theme) => theme.palette["success"].darker : (theme) => theme.palette["error"].darker }}>
                              {patient.mapStatus === 'Admitted' ? "Admitted" : "Discharged"}
                            </Typography>
                          </TableCell>
                          <TableCell>{patient.wardNo}</TableCell>
                          <TableCell>{patient.bedNo}</TableCell>
                          <TableCell>
                            {new Date(Number(patient.admittedTime)).toLocaleString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            }).replace(",", "")}
                          </TableCell>
                          <TableCell>
                            {patient.mapStatus === 'Discharged' && (
                              new Date(Number(patient.dischargeTime)).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              }).replace(",", "")
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              ) : (
                <center>
                  No History
                </center>
              )}
            </TabPanel>

          </CardContent>
        </Card>
        <div style={{
          height: "50px"
        }} />
      </Container >
    </div >
  );
}

export default PatientView;
