import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Add, Cancel, Close, Edit, Home, PlaylistRemoveRounded } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Container,
    Dialog,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


//-----------------------------------------------------------------------------

const EditPatient = () => {

    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState(new Date().toISOString().slice(0, 10));
    const [gender, setGender] = useState('Female');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPatientId, setSelectedPatientId] = useState('');
    const [patientInfo, setPatientInfo] = useState('false');

    const [companies, setcompanies] = useState([]);
    const [assignedDevices, setAssignedDevices] = useState([]);
    const [selectedDevicetoDeselect, setSelectedDevicetoDeselect] = useState([]);
    const [selectedDevicestoAssign, setSelectedDevicetoAssign] = useState([]);
    const [device, setDevice] = useState([]);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [hover, setHover] = useState(false);
    const [openAddMoreDevice, setOpenAddMoreDevice] = useState(false);

    const loadPatientDetailsById = (patientId) => {
        ApiCalls.getdetails("getpatient/" + patientId + "/patients?")
            .then((response) => {
                const data = response.data.patient[0];
                console.log(data);
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
                setDob(data.dob);
                setGender(data.gender);
                setPhone(data.phone);
                setStreet(data.address.street);
                setCity(data.address.city);
                setState(data.address.state);
                setPostalCode(data.address.postalcode);
                setCountry(data.address.country);
                setImage(data.image);
            })
    }

    const loadCompanyList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies")
            .then((response) => {
                console.log("Company Details");
                console.log("***********************************");
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                console.log(options);
                setcompanies(options)
            })
    }

    const loadDeviceAssigned = (patientId) => {
        ApiCalls.getdetails("patient/" + patientId + "/getdevices")
            .then((response) => {
                console.log(response.data);
                if (response.data.device !== undefined) {
                    setAssignedDevices(response.data.device);
                } else {
                    setAssignedDevices([]);
                }
            });
    }

    const loadDeviceList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
          tenantId = secureLocalStorage.getItem("UUID");
          companyId = "0";
          physicianId = "0";
        } else if (rolename === "Company") {
          tenantId = secureLocalStorage.getItem("tenantId");
          companyId = secureLocalStorage.getItem("UUID");
          physicianId = "0";
        } else if (rolename === "Physician") {
          tenantId = secureLocalStorage.getItem("tenantId");
          companyId = secureLocalStorage.getItem("compId");
          physicianId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
            .then((response) => {
                console.log("Device list")
                console.log(response.data.device);
                if (response.data.device != undefined) {
                    setDevice(response.data.device);
                } else {
                    setDevice([]);
                }
            }).catch((error) => {
                console.log(error);
                setDevice([]);
            })
    }

    useEffect(() => {
        const patientId = secureLocalStorage.getItem("patientIdToEdit");
        console.log(patientId)
        setSelectedPatientId(patientId);
        loadCompanyList();
        loadDeviceList();
        loadPatientDetailsById(patientId);

        var roleId = secureLocalStorage.getItem("roleId");

        ApiCalls.getdetails("roleId" + roleId + "/getpatientprivilegedetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientDevice;
                    console.log("PatientInfo: " + patientInfo);
                    setPatientInfo(patientInfo);
                    if (patientInfo === "true") {
                        loadDeviceAssigned(patientId);
                    }
                }
            })
    }, [])

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                case 'email':
                    setEmailError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    break;
                case 'dob':
                    setDobError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case 'dob':
                const selectedDate = new Date(event.target.value);
                const today = new Date();
                if (selectedDate >= today) {
                    setDobError(true);
                } else {
                    setDobError(false);
                }
                setDob(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
    };

    const maxDate = formatDate(new Date());

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleDeselectDevice = (devices) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var deviceId = devices.deviceuuid;
                    var patientId = devices.patientId;

                    console.log(deviceId, patientId)

                    ApiCalls.delete("device/" + deviceId + "/patient/" + patientId + "/deselectDevice")
                        .then(function (response) {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deselected Successfuly!", {
                                    icon: "success",
                                });
                                loadDeviceAssigned(patientId);
                            }
                        })
                }
            })
    }

    const handleUpdate = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!firstName) {
            setFirstNameError(true);
        } else if (!lastName) {
            setLastNameError(true);
        } else if (!email) {
            setEmailError(true);
        } else if (!emailRegex.test(email)) {
            setEmailError(true);
        } else if (!dob) {
            setDobError(true);
        } else if (!phone) {
            setPhoneError(true);
        } else if (!phoneRegex.test(phone)) {
            setPhoneError(true);
        } else {
            var patientId = secureLocalStorage.getItem("patientIdToEdit");
            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = selectedCompany;

            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            }

            var data = {
                tenantId: tenantId,
                companyId: companyId,
                physicianId: "0",// physicianId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                gender: gender,
                roleId: "6",
                phone: phone,
                address: address,
                image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
            }

            ApiCalls.edit("organizations/0/tenants/" + tenantId + "/patients/" + patientId + "/updatePatient", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureLocalStorage.getItem("PatientName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else {
                        setRedirectToList(true);
                    }
                })
        }
    }

    const handleOpenDeviceList = () => {
        setOpenAddMoreDevice(true);
    }

    const handleCloseDeviceList = () => {
        setOpenAddMoreDevice(false);
    }

    if (redirectToList) {
        return (
            <Redirect to={'/managepatient'} />
        )
    }

    const handleAssignDevicetoPatient = () => {
        console.log(selectedDevicestoAssign[0].id);
        console.log(selectedPatientId);

        var data = {
            patients: [
                {
                    patientuuid: selectedPatientId
                }
            ]
        }

        ApiCalls.register("device/" + selectedDevicestoAssign[0].id + "/patient/assign", data)
            .then((response => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal((secureLocalStorage.getItem("DeviceName") + "Assigned"), {
                        icon: "success",
                    });
                    handleCloseDeviceList();
                    loadDeviceAssigned(selectedPatientId);
                } else if (response.data.status.details === "Device Already Assigned To The Patient") {
                    swal((secureLocalStorage.getItem("DeviceName") + " Already Assigned"), {
                        icon: "warning",
                    });
                }
            })).catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managepatient">
                            <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("PatientName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureLocalStorage.getItem("PatientName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureLocalStorage.getItem("PatientName")}
                        </Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="circular"
                                                src={image}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 150,
                                                    height: 150,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="ooui:user-avatar-outline" color="#4481eb" width="80" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <TextField
                                                name="firstName"
                                                label="First Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={firstName}
                                                fullWidth
                                                error={firstNameError}
                                                helperText={firstNameError && "First Name is required"}
                                            />
                                            <TextField
                                                name="lastName"
                                                label="Last Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                value={lastName}
                                                error={lastNameError}
                                                helperText={lastNameError && "Last Name is required"}
                                            />
                                            <TextField
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                value={email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && "Email address is required"}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                id="date"
                                                name="dob"
                                                label="DOB"
                                                type="date"
                                                value={dob}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: maxDate,
                                                }}
                                                error={dobError}
                                                helperText={dobError && "Enter a valid DOB"}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    label="Gender"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <MenuItem value="Female">Female</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                label="Phone"
                                                value={phone}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && "Phone Number is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="street"
                                                label="Street Address"
                                                onChange={handleChange}
                                                value={street}
                                                fullWidth
                                            />
                                            <TextField
                                                name="city"
                                                label="City"
                                                fullWidth
                                                value={city}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                name="state"
                                                label="State"
                                                value={state}
                                                fullWidth
                                                onChange={handleChange}
                                            />

                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="postalCode"
                                                label="Postal Code"
                                                value={postalCode}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            <TextField
                                                name="country"
                                                label="Country"
                                                fullWidth
                                                value={country}
                                                onChange={handleChange}
                                            />
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="error"
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Cancel />}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleUpdate}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Edit />}
                                            >
                                                Update
                                            </Button>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                    display: patientInfo === "false" ? "none" : ""
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Assigned " + secureLocalStorage.getItem("DeviceName")}
                        </Typography>}
                        action={
                            <Tooltip title='Assign More'>
                                <Fab onMouseDown={handleMouseDown} onClick={handleOpenDeviceList} size="small" color="primary" aria-label="add">
                                    <Add />
                                </Fab>
                            </Tooltip>
                        }
                    />
                    <CardContent sx={{
                        maxHeight: 300,
                        overflowY: "scroll",
                    }}>
                        <Grid container spacing={2}>
                            {
                                assignedDevices.length > 0 ?
                                    assignedDevices.map((device) => (
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Card sx={{
                                                position: 'relative',
                                                boxShadow: 3,
                                                '&:hover .delete-icon': {
                                                    display: 'block',
                                                },
                                            }}>
                                                <Tooltip title={"Deselect " + secureLocalStorage.getItem("DeviceName")}>
                                                    <PlaylistRemoveRounded
                                                        className="delete-icon" sx={{
                                                            fontSize: "30px",
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            display: 'none',
                                                            color: pink[500],
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                        }}
                                                        onClick={() => handleDeselectDevice(device)}
                                                    />
                                                </Tooltip>
                                                <CardHeader
                                                    avatar={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar
                                                            variant='rounded'
                                                            alt={device.devicename}
                                                            src={device.deviceimageName}
                                                            sx={{ width: 120, height: 120, objectFit: "contain" }} />
                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Device Name
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {device.devicename}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Device Macid
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {device.decmacid}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    } />
                                            </Card>
                                        </Grid>
                                    )) : <center>
                                        {"No " + secureLocalStorage.getItem("DeviceName") + " Assigned"}
                                    </center>
                            }
                        </Grid>
                    </CardContent>
                </Card>
                <div style={{
                    height: "15px"
                }} />

                <div>
                    <Dialog open={openAddMoreDevice} onClose={handleCloseDeviceList}>
                        <CardHeader
                            title={"Add " + secureLocalStorage.getItem("DeviceName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseDeviceList}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {
                                device.length > 0 ?
                                    <>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            maxHeight: 400,
                                            overflowY: "scroll"
                                        }} >
                                            {device
                                                .map((device) => (
                                                    <FormControlLabel
                                                        key={device.deviceuuid}
                                                        control={<Checkbox
                                                            checked={selectedDevicestoAssign.some(
                                                                (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                            )}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                const deviceId = device.deviceuuid;
                                                                const deviceName = device.devicename;
                                                                const deviceMacId = device.decmacid;
                                                                if (checked) {
                                                                    setSelectedDevicetoAssign([{ id: deviceId, name: deviceName, macid: deviceMacId }]);
                                                                } else {
                                                                    setSelectedDevicetoAssign([]);
                                                                }
                                                            }}
                                                            value={device.deviceuuid}
                                                        />}
                                                        label={<>
                                                            <CardHeader
                                                                avatar={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            variant='rounded'
                                                                            alt={device.devicename}
                                                                            src={device.deviceimageName}
                                                                            sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                    {device.devicename}
                                                                                </Typography>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                    {device.decmacid}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                } />

                                                        </>}
                                                    />
                                                ))}
                                        </Box>
                                    </>
                                    : <Box sx={{

                                        alignItems: "center"  // Center the content vertically
                                    }}>
                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                            No {secureLocalStorage.getItem("DeviceName")} Found
                                        </div>
                                    </Box>
                            }
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseDeviceList} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} disabled={!selectedDevicestoAssign.length > 0} onClick={handleAssignDevicetoPatient} fullWidth >Submit</Button>
                        </CardActions>
                    </Dialog>
                </div>

            </Container>
        </div>
    )
}

export default EditPatient
