import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

class SimpleQueue {
  constructor() {
    this.queue = [];
  }

  push(item) {
    this.queue.push(item);
  }

  pop() {
    return this.queue.shift();
  }

  size() {
    return this.queue.length;
  }
}

const FixedSizeQueue = class {
  constructor(maxSize) {
    this.queue = [];
    this.maxSize = maxSize;
  }

  push(item) {
    this.queue.push(item);
    if (this.queue.length > this.maxSize) {
      this.queue.shift(); // Remove the first element if the queue is full
    }
  }

  toArray() {
    return this.queue;
  }
};

const LiveLineChart = ({
  parameterName,
  parameterId,
  onParamValueChange,
  deviceId,
}) => {
  const dataQueue = useRef(new FixedSizeQueue(100));
  const chartData = useRef({
    labels: [],
    datasets: [
      {
        label: parameterName,
        data: [],
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.4,
        borderWidth: 1,
        showLine: true,
        pointRadius: 0,
      },
    ],
  });
  const [elapsedTime, setElapsedTime] = useState(0);
  const [newDataPoints, setNewDataPoints] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);
  const [newDataAvailable, setNewDataAvailable] = useState(false);
  const [alarmBorderColor, setAlarmBorderColor] =
    useState("rgba(75,192,192,1)");
  const alarmThreshold = 99; // Set your desired threshold value
  const warningThreshold = 96;
  const timeWindowSeconds = 10;
  const dataPointInterval = 100;
  const maxDataPoints = 50;
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: parameterName,
        data: [], // Add an empty array for waveform data
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.4,
        borderWidth: 1,
        showLine: true,
        pointRadius: 0,
      },
    ],
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        borderColor: alarmBorderColor,
      },
    },
  };

  const processMessage = (msgString) => {
    let doubleArray = null;
    let paramValues = [];

    try {
      const commandParams = JSON.parse(msgString);
      const command = commandParams["PacketID"];

      switch (command) {
        case "WS_DEVICE_INFO":
          // Handle WS_DEVICE_INFO case if needed
          break;

        case "WS_PARAM_INFO":
          // Handle WS_PARAM_INFO case if needed
          break;

        case "WS_PARAM_DATA":
          const paramUUID = commandParams["ParamUUID"];
          const paramType = commandParams["ParamType"];
          const seqNum = commandParams["SNo"];

          const dataType = commandParams.hasOwnProperty("DataType")
            ? commandParams["DataType"]
            : "Double";

          console.log(paramUUID, parameterId);

          if (paramType === "Stream") {
            const ParamUUID = commandParams["ParamUUID"];
            if (ParamUUID === parameterId) {
              const data = commandParams["Data"];
              if (dataType === "Double") {
                doubleArray = convertFromBase64StringToDouble(data);
              } else {
                doubleArray =
                  convertBase64ComaSeparatedStringToDoubleArray(data);
              }
            }
          } else {
            const paramValue = commandParams["Value"];
            console.log("paramValues: " + paramValue);
            const valD = parseFloat(paramValue);
            // console.log("valId: " + valD);
            paramValues[paramUUID] = valD;

            // Check if paramValue is not null or undefined before sending the data
            // Check if the incoming value is not -100 before updating
            if (valD !== -100) {
              // Check if the data exceeds the threshold
              if (valD >= alarmThreshold) {
                // Data exceeds the limit, set red border
                console.log("alarmThreshold");
                setAlarmBorderColor("rgba(255,0,0,1)");
              } else if (valD >= warningThreshold) {
                // Data beyond the limit but below the threshold, set yellow border
                console.log("warningThreshold");
                setAlarmBorderColor("rgba(255,255,0,1)");
              } else {
                // Data within normal range, set default color
                setAlarmBorderColor("rgba(75,192,192,1)");
              }

              onParamValueChange({
                paramUUID,
                paramName: commandParams["ParamName"],
                value: valD,
              });
            }
          }
          break;

        default:
          console.error("Unknown command:", command);
          break;
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    return doubleArray;
  };

  const convertBase64ComaSeparatedStringToDoubleArray = (message) => {
    try {
      const byteData = atob(message);
      const intStrings = byteData.split(",");
      const doubleArray = intStrings.map((str) => parseInt(str));
      return doubleArray;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const convertFromBase64StringToDouble = (message) => {
    try {
      const byteData = atob(message);
      const byteArray = new Uint8Array(byteData.length);
      for (let i = 0; i < byteData.length; i++) {
        byteArray[i] = byteData.charCodeAt(i);
      }

      const doubleArray = [];
      const dataView = new DataView(byteArray.buffer);

      // Ensure that there is enough data for at least one double
      if (byteArray.length >= 8) {
        for (let i = 0; i < byteArray.length; i += 8) {
          // Check if there is enough data remaining for the current double
          if (i + 8 <= byteArray.length) {
            doubleArray.push(dataView.getFloat64(i, true)); // true for little-endian
          } else {
            // console.error("Not enough data for a double at offset", i);
            // console.log("Current byteArray:", byteArray);
            console.log("Current doubleArray:", doubleArray);
            break;
          }
        }
      } else {
        console.error("Not enough data for a double");
      }

      return doubleArray;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const createJson = (deviceID, paramID, samplingRate, minVal, maxVal) => {
    return {
      PacketID: "WS_DEVICE_CONSUME_REQ",
      DeviceUUID: deviceID,
      ParamUUID: paramID,
      SamplingRate: samplingRate,
      WindowSize: 10,
      MinYVal: minVal,
      MaxYVal: maxVal,
      isStream: true,
      labelValues: null,
    };
  };

  const createParamJson = (deviceID, paramID, samplingRate, minVal, maxVal) => {
    return {
      PacketID: "WS_PARAM_CONSUME_REQ",
      DeviceUUID: deviceID,
      ParamUUID: paramID,
      SamplingRate: samplingRate,
      WindowSize: 10,
      MinYVal: minVal,
      MaxYVal: maxVal,
      isStream: true,
      labelValues: null,
    };
  };

  useEffect(() => {
    const ws = new WebSocket("ws://178.128.165.237:4567/consumer");

    ws.onopen = () => {
      const SLDeviceID = "671122";
      const SLparamID = "20001";
      const zonDeviceID = "679988";
      const zonParamId = "10000";
      const samplingRate = 250;
      const minVal = 0.0;
      const maxVal = 128.0;

      const SLdeviceJson = createJson(
        SLDeviceID,
        SLparamID,
        samplingRate,
        minVal,
        maxVal
      );
      const SLparamJson = createParamJson(
        SLDeviceID,
        SLparamID,
        samplingRate,
        minVal,
        maxVal
      );

      const zondeviceJson = createJson(
        zonDeviceID,
        zonParamId,
        samplingRate,
        minVal,
        maxVal
      );
      const zonparamJson = createParamJson(
        zonDeviceID,
        zonParamId,
        samplingRate,
        minVal,
        maxVal
      );

      ws.send(JSON.stringify(SLdeviceJson));
      ws.send(JSON.stringify(zondeviceJson));
      ws.send(JSON.stringify(SLparamJson));
      ws.send(JSON.stringify(zonparamJson));
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onmessage = (event) => {
      const message = event.data;

      if (isValidJSON(message)) {
        console.log(message);
        const newData = processMessage(message);
        console.log(newData);
        dataQueue.current.push(newData);
      }
    };

    const streamData = () => {
      const startTime = performance.now();

      const dataArray = dataQueue.current.toArray();

      if (dataArray.length > 0) {
        const doubleArray = dataArray.pop();

        if (doubleArray) {
          const newPoint = doubleArray.map((value, i) => ({
            x: elapsedTime + i * dataPointInterval,
            y: value,
          }))[0];

          // Update the chart data for a single new point using useRef
          chartData.current.labels.push(newPoint.x);
          chartData.current.datasets[0].data.push(newPoint.y);

          console.log(
            "chartData.current.labels.length: " +
              chartData.current.labels.length
          );
          console.log("maxDataPoints: " + maxDataPoints);

          // Trim excess data points
          while (chartData.current.labels.length > maxDataPoints) {
            chartData.current.labels.shift();
            chartData.current.datasets[0].data.shift();
          }

          // Check if the maximum time has reached
          if (newPoint.x > timeWindowSeconds * 1000) {
            // Shift the entire dataset to the left
            chartData.current.labels = chartData.current.labels.map(
              (label) => label - dataPointInterval
            );

            // Update the chart data without triggering a re-render
            setData({ ...chartData.current });
          } else {
            // Update the chart data without triggering a re-render
            setData({ ...chartData.current });
          }

          const endTime = performance.now();
          const deltaTime = endTime - startTime;
          setElapsedTime((prevTime) => prevTime + deltaTime);
        }

        requestAnimationFrame(streamData);
      }
    };

    const intervalId = setInterval(streamData, dataPointInterval);

    return () => {
      clearInterval(intervalId);
      ws.close();
    };
  }, [dataQueue, setData]);

  useEffect(() => {
    if (newDataAvailable) {
      setData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            data: newDataPoints,
          },
        ],
      }));

      setNewDataAvailable(false);
      setNewDataPoints([]);
    }
  }, [newDataAvailable, newDataPoints]);

  function isValidJSON(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <div>
      <Line data={data} options={chartOptions} />
    </div>
  );
};

export default LiveLineChart;
