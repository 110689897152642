import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//components
import NoImg from "../../assets/images/noimage.jpg";
import Iconify from '../../components/iconify/Iconify';
import Preview from "../../layout/Preview";

//mui-components
import { Close, Delete, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}

//-------------------------------------------------------------------------------


const ManageProducer = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState('');
    const [producerId, setProducerId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [producerIdToEdit, setProducerIdToEdit] = useState('');
    const [nameError, setNameError] = useState('');
    const [producerIdError, setproducerIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [networkIP, setNetworkIP] = useState('');
    const [webSockAddress, setWebSockAddress] = useState('');
    const [selectedProducerType, setSelectedProducerType] = useState('');

    const [currentStep, setCurrentStep] = useState(1);

    const [openAddProducer, setOpenAddProducer] = useState(false);
    const [openEditProducer, setOpenEditProducer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loadDevicesList, setLoadDevicesList] = useState(true);
    const [loadDeviceAssigned, setLoadDeviceAssigned] = useState(true);
    const [openProducer, setOpenProducer] = useState(false);
    const [openAddMoreDevice, setOpenAddMoreDevice] = useState(false);

    const [producer, setProducer] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedDevicestoAssign, setSelectedDevicetoAssign] = useState([]);
    const [selectedProducerToView, setSelectedProducerToView] = useState([]);
    const [devicesAssgined, setDevicesAssigned] = useState([]);
    const [selectedDevicetoDeselect, setSelectedDevicetoDeselect] = useState([]);

    const loadProducerList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");

        ApiCalls.getdetails("tenant/" + tenantId + "/company/" + companyId + "/producers")
            .then((response) => {
                console.log(response.data);
                if (response.data.producer !== undefined) {
                    setProducer(response.data.producer);
                    setLoading(false);
                } else {
                    setProducer([]);
                    setLoading(false);
                }
            }).catch((err) => {
                console.log(err);
                setProducer([]);
                setLoading(false);
            })

    }

    const loadDeviceList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("compId");
            physicianId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
            .then((response) => {
                console.log(response.data);
                if (response.data.device !== undefined) {
                    console.log(response.data.device);
                    for (var i = 0; i < response.data.device.length; i++) {
                        setDevices(response.data.device);
                        setLoadDevicesList(false);
                    }
                } else {
                    setLoadDevicesList(false);
                    setDevices([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoadDevicesList(false);
                setDevices([]);
            })
    };

    useEffect(() => {
        setLoading(true);
        loadProducerList();
        loadDeviceList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleOpenAddProducer = () => {
        setOpenAddProducer(true);
    }

    const handleCloseAddProducer = () => {
        setSelectedDevicetoAssign([]);
        setOpenAddProducer(false);
        loadProducerList();
    }

    const filteredProducer = producer.filter((proddata) =>
        proddata.producerName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setNameError(true);
                    break;
                case 'producerId':
                    setproducerIdError(true);
                    break;
                case 'password':
                    setPasswordError(true);
                    break;
                case 'confirmPassword':
                    setConfirmPasswordError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'producerId':
                setproducerIdError(false);
                setProducerId(event.target.value);
                break;
            case 'password':
                setPasswordError(false);
                setPassword(event.target.value);
                break;
            case 'confirmPassword':
                setConfirmPasswordError(false);
                setConfirmPassword(event.target.value);
                break;
            case 'networkIP':
                setNetworkIP(event.target.value);
                break;
            case 'webSockAddress':
                setWebSockAddress(event.target.value);
                break;
            default:
                break;
        }

        // Check if password and confirm password match
        if (event.target.name === 'confirmPassword') {
            if (event.target.value !== password) {
                setConfirmPasswordError(true);
            } else {
                setConfirmPasswordError(false);
            }
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleSelectDevice = (event, id, macid, name) => {
        if (event.target.checked) {
            setSelectedDevicetoAssign([...selectedDevicestoAssign, { id, name, macid }]);
        } else {
            setSelectedDevicetoAssign(selectedDevicestoAssign.filter(
                (d) => d.id !== id || d.macid !== macid
            ));
        }
    }

    const handleAddProducer = () => {
        if (!name) {
            setNameError(true);
        } else if (!producerId) {
            setproducerIdError(true);
        } else if (!password) {
            setPasswordError(true);
        } else if (!confirmPassword) {
            setConfirmPasswordError(true);
        } else {
            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = secureLocalStorage.getItem("compId");

            var a = [];
            var list = selectedDevicestoAssign.length;
            for (var i = 0; i < list; i++) {
                var counter = selectedDevicestoAssign[i];
                const deviceuuid = counter.id;
                const devicemacId = counter.macid;
                var s = { deviceuuid: deviceuuid, devmacId: devicemacId }
                a.push(s);
            }

            var data = {
                producerName: name,
                producerDevId: producerId,
                producerPassword: confirmPassword,
                networkIP: networkIP,
                websocketAddress: webSockAddress,
                device: a
            }
            ApiCalls.register("tenant/" + tenantId + "/company/" + companyId + "/producer", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Producer Successfully Registered", {
                            icon: "success",
                        });
                        setSelectedDevicetoAssign([]);
                        handleCloseAddProducer();
                    } else if (response.data.status.details === 'Already existing Producer') {
                        swal("Producer Already Registered", {
                            icon: "info",
                        });
                    } else if (response.data.status.details === "Device Already assigned to a producer") {
                        swal("Device Already assigned to a producer", {
                            icon: "info",
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                    if (err.message === "Request failed with status code 409") {
                        swal("Producer Already Registered", {
                            icon: "info",
                        });
                    }
                })
        }
    }

    const handleOpenEditProducer = (producerId) => {
        setProducerIdToEdit(producerId);
        ApiCalls.getdetails("producer/" + producerId + "/producerById")
            .then((response) => {
                console.log(response.data);
                if (response.data.producer != undefined) {
                    setName(response.data.producer[0].producerName);
                    setProducerId(response.data.producer[0].producerDevId)
                    setPassword(response.data.producer[0].producerPassword)
                    setNetworkIP(response.data.producer[0].networkIP)
                    setWebSockAddress(response.data.producer[0].websocketAddress)
                }
            })
        setOpenEditProducer(true);
    }

    const handleDeleteProducer = (producerId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.delete("producer/" + producerId + "/deleteproducer")
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Deleted Successfuly!", {
                                icon: "success",
                            });
                            loadProducerList();
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        })

    }

    const handleCloseEditProducer = () => {
        setOpenEditProducer(false);
        loadProducerList();
    }

    const handleUpdateProducer = () => {
        if (!name) {
            setNameError(true);
        } else if (!producerId) {
            setproducerIdError(true);
        } else {
            const data = {
                producerName: name,
                producerDevId: producerId,
                producerPassword: password,
                networkIP: networkIP,
                websocketAddress: webSockAddress
            }

            ApiCalls.edit("producer/" + producerIdToEdit + "/updateproducer", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Producer Successfully Updated", {
                            icon: "success",
                        });
                        handleCloseEditProducer();
                    }
                })
        }
    }

    const loadDeviceListByProducer = (producerId) => {
        setLoadDeviceAssigned(true);

        ApiCalls.getdetails("producer/" + producerId + "/getdeviceassigned")
            .then((response) => {
                console.log(response.data);
                const data = response.data.device;
                if (data !== undefined) {
                    setDevicesAssigned(data);
                    setLoadDeviceAssigned(false);
                } else {
                    setDevicesAssigned([]);
                    setLoadDeviceAssigned(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setDevicesAssigned([]);
                setLoadDeviceAssigned(false);
            })
    }

    const handleOpenProducer = (producer) => {
        setSelectedDevicetoAssign([]);
        setSelectedProducerToView(producer);
        loadDeviceListByProducer(producer.producerId);
        setOpenProducer(true);
    }

    const handleCloseProducer = () => {
        setSelectedDevicetoAssign([]);
        setSelectedDevicetoDeselect([]);
        setOpenProducer(false);
        loadProducerList();
    }

    const handleOpenAddMoreDevice = () => {
        setOpenAddMoreDevice(true);
    }

    const handleCloseAddMoreDevice = () => {
        setOpenAddMoreDevice(false);
        setCurrentStep(1)
        setSelectedDevicetoAssign([]);
        loadDeviceListByProducer(selectedProducerToView.producerId)
    }

    const handleAssignDeviceToProducer = () => {

        console.log(selectedDevicestoAssign);
        let producerId = selectedProducerToView.producerId;
        var a = [];
        var list = selectedDevicestoAssign.length;
        for (var i = 0; i < list; i++) {
            var counter = selectedDevicestoAssign[i];
            const deviceuuid = counter.id;
            const devicemacId = counter.macid;
            const prodType = counter.producerType;
            var s = { deviceuuid: deviceuuid, devmacId: devicemacId, producerType: prodType };
            a.push(s);
        }
        var data = {
            device: a,
        };

        if (selectedDevicestoAssign.length > 0) {
            ApiCalls.register("producer/" + producerId + "/device/assign", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal((secureLocalStorage.getItem("DeviceName") + "Assigned"), {
                            icon: "success",
                        });
                        handleCloseAddMoreDevice(producerId);
                    } else if (
                        response.data.status.details === "Device Already Assigned To Producer"
                    ) {
                        swal((secureLocalStorage.getItem("DeviceName") + " Already Assigned"), {
                            icon: "warning",
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            swal({
                title: "Warning",
                text: "Select A Device",
                icon: "info"
            })
        }
    }

    const handleDeselectDevices = () => {
        let producerId = selectedProducerToView.producerId;
        var a = [];
        var list = selectedDevicetoDeselect.length;
        for (var i = 0; i < list; i++) {
            var counter = selectedDevicetoDeselect[i];
            const deviceuuid = counter.id;
            const devicemacId = counter.macid;
            var s = { deviceuuid: deviceuuid, devmacId: devicemacId };
            a.push(s);
        }
        var data = {
            device: a,
        };

        console.log(data);

        swal({
            title: "Are you sure?",
            text: "Do you want to Deselect this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                ApiCalls.edit("producer/" + producerId + "/deselectDevice", data)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Device Deselected!", {
                                icon: "success",
                            });
                            loadDeviceListByProducer(selectedProducerToView.producerId);
                            loadProducerList();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    const handleNextStep = () => {
        console.log(selectedDevicestoAssign);
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleChangeProducerType = (event, deviceId) => {
        const { value } = event.target;

        setSelectedDevicetoAssign((prevState) => {
            const updatedDevices = prevState.map((device) => {
                if (device.id === deviceId) {
                    return {
                        ...device,
                        producerType: value,
                    };
                }
                return device;
            });
            return updatedDevices;
        });

        setSelectedProducerType((prevState) => {
            const updatedProducerType = { ...prevState };
            if (value) {
                updatedProducerType[deviceId] = true;
            } else {
                delete updatedProducerType[deviceId];
            }
            return updatedProducerType;
        });
    };


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Manage Producer" />
                    </Breadcrumbs>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAddProducer} onMouseDown={handleMouseDown}>
                        New Producer
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title="Producer List"
                        action={
                            <TextField
                                label="Search Producer"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent >
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredProducer.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredProducer.map((producer) => (
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Card
                                                key={producer.producerId}
                                                sx={{
                                                    maxWidth: 200,
                                                    boxShadow: 3,
                                                    backgroundColor: "#F4F6F8",
                                                    '&:hover': {
                                                        border: '2px solid #D1E9FC',
                                                        boxShadow: 'none'
                                                    },
                                                }}>
                                                <CardContent
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => { handleOpenProducer(producer) }}
                                                >
                                                    <Box sx={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        <Icon icon="solar:server-square-update-bold-duotone" width="70" />
                                                    </Box>
                                                    <Box sx={{ mt: 2, mb: 2 }}>
                                                        <center>
                                                            <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                                {producer.producerName}
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                {producer.departmentName}
                                                            </Typography>
                                                        </center>
                                                    </Box>
                                                    <Divider />
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleOpenEditProducer(producer.producerId)} fullWidth startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" onMouseDown={handleMouseDown} onClick={() => handleDeleteProducer(producer.producerId)} fullWidth startIcon={<Delete />}>Delete</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No Producer Found
                                </center>
                            )
                        }
                    </CardContent>
                </Card>

            </Container>


            <div>
                <Dialog open={openAddProducer} onClose={handleCloseAddProducer} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Add Producer
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddProducer}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        <CardContent sx={{
                            maxHeight: "400px",
                            overflowY: "scroll"
                        }}>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="name"
                                    label="Producer Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'lucide:server-cog'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                                <TextField
                                    name="producerId"
                                    label="Producer Id"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'lucide:server-cog'} />
                                            </InputAdornment>
                                        ),
                                        ...(producerIdError && { autoComplete: 'off' }), // turn off autocomplete only if there's an error
                                    }}
                                    error={producerIdError}
                                    helperText={producerIdError && "Cms Id is required"}
                                />

                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'new-password', // use "new-password" for password fields
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        ...(passwordError && { autoComplete: 'off' }), // turn off autocomplete only if there's an error
                                    }}
                                    error={passwordError}
                                    helperText={passwordError && "Password is required"}
                                />

                                <TextField
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showConfirmPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={confirmPasswordError}
                                    helperText={confirmPasswordError && "Password must match"}
                                />
                                <TextField
                                    name="networkIP"
                                    label="Network IP"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'eos-icons:ip'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    name="webSockAddress"
                                    label="WebSocket Address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'tabler:network'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid container spacing={3} sx={{
                                    overflowY: 'scroll',
                                    maxHeight: 250
                                }}>
                                    {
                                        loadDevicesList ?
                                            <center>
                                                <CircularProgress />
                                            </center>
                                            : devices.length > 0 ?
                                                devices.map((item) => (
                                                    <Grid item xs={2} sm={2} md={3}>
                                                        <ul style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                <input
                                                                    type="checkbox"
                                                                    name={item.devicename}
                                                                    value={item.deviceuuid}
                                                                    id={item.deviceuuid}
                                                                    onChange={(e) => handleSelectDevice(e, item.deviceuuid, item.decmacid, item.devicename)}
                                                                />
                                                                <label for={item.deviceuuid}><img src={item.deviceimageName == undefined ? NoImg : item.deviceimageName} /></label>
                                                                <span class="checkbox-label font-weight-bold text-muted">{item.devicename}</span>
                                                            </li>
                                                        </ul>
                                                    </Grid>
                                                ))
                                                : <center>
                                                    {"No " + secureLocalStorage.getItem("DeviceName") + " found"}
                                                </center>
                                    }

                                </Grid>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddProducer} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleAddProducer} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openEditProducer} onClose={handleCloseEditProducer} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Update Producer
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseEditProducer}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                <TextField
                                    name="name"
                                    label="Producer Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={name}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'lucide:server-cog'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                                <TextField
                                    name="producerId"
                                    label="Producer Id"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={producerId}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'off',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'lucide:server-cog'} />
                                            </InputAdornment>
                                        ),
                                        ...(producerIdError && { autoComplete: 'off' }), // turn off autocomplete only if there's an error
                                    }}
                                    error={producerIdError}
                                    helperText={producerIdError && "Cms Id is required"}
                                />

                                <TextField
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        autoComplete: 'new-password', // use "new-password" for password fields
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        ...(passwordError && { autoComplete: 'off' }), // turn off autocomplete only if there's an error
                                    }}
                                    error={passwordError}
                                    helperText={passwordError && "Password is required"}
                                />

                                <TextField
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'mdi:password-add'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                >
                                                    <Iconify icon={showConfirmPassword ? 'bx:show' : 'bx:hide'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={confirmPasswordError}
                                    helperText={confirmPasswordError && "Password must match"}
                                />
                                <TextField
                                    name="networkIP"
                                    label="Network IP"
                                    onChange={handleChange}
                                    value={networkIP}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'eos-icons:ip'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                                <TextField
                                    name="webSockAddress"
                                    label="WebSocket Address"
                                    onChange={handleChange}
                                    value={webSockAddress}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon={'tabler:network'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={nameError}
                                    helperText={nameError && "Name is required"}
                                />
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseEditProducer} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleUpdateProducer} fullWidth >Submit</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openProducer} onClose={handleCloseProducer} fullScreen={fullScreen}>
                    <Card sx={{
                        minWidth: 500
                    }}>
                        <CardHeader
                            title={secureLocalStorage.getItem("DeviceName") + " Details"}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseProducer}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent sx={{
                            maxHeight: 350,
                            overflowY: "scroll"
                        }}>
                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                {
                                    loadDeviceAssigned ?
                                        <>
                                            <CardHeader
                                                avatar={
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Skeleton
                                                            variant="rounded"
                                                            sx={{
                                                                width: 150,
                                                                height: 150
                                                            }}
                                                        />
                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Device Name
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Device Macid
                                                                </Typography>
                                                                <Skeleton
                                                                    variant="text"
                                                                    sx={{ fontSize: "1rem", width: 100 }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                }
                                            />

                                            <CardContent>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    maxHeight: 400,
                                                    overflowY: "scroll"
                                                }}>
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Model Name
                                                        </Typography>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Model Number
                                                        </Typography>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Version Number
                                                        </Typography>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 100 }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </> :
                                        devicesAssgined.length > 0 ?
                                            devicesAssgined.map((device) => (
                                                <CardContent>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name={device.devicename}
                                                                value={device.deviceuuid}
                                                                id={device.deviceuuid}
                                                                checked={selectedDevicetoDeselect.some(
                                                                    (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                )}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    const deviceId = device.deviceuuid;
                                                                    const deviceName = device.devicename;
                                                                    const deviceMacId = device.decmacid;
                                                                    if (checked) {
                                                                        setSelectedDevicetoDeselect([
                                                                            ...selectedDevicetoDeselect,
                                                                            { id: deviceId, name: deviceName, macid: deviceMacId },
                                                                        ]);
                                                                    } else {
                                                                        setSelectedDevicetoDeselect((prevState) =>
                                                                            prevState.filter(
                                                                                (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                            )
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <>
                                                                <CardHeader
                                                                    avatar={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Avatar
                                                                            variant='rounded'
                                                                            alt={device.devicename}
                                                                            src={device.deviceimageName}
                                                                            sx={{ width: 120, height: 120, objectFit: "contain" }} />
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                    Device Name
                                                                                </Typography>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                    {device.devicename}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                    Device Macid
                                                                                </Typography>
                                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                    {device.decmacid}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    } />
                                                                <CardContent>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                Model Name
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                {device.modelName == undefined
                                                                                    ? " "
                                                                                    : device.modelName}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                Model Number
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                {device.modelNumber == undefined
                                                                                    ? "Not Provided"
                                                                                    : device.modelNumber}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box sx={{ mx: 2, my: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                                Version Number
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                                {device.version === undefined
                                                                                    ? " "
                                                                                    : device.version}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </CardContent></>
                                                        }
                                                    />

                                                    <Divider />
                                                </CardContent>
                                            )) :
                                            <center>
                                                {"No " + secureLocalStorage.getItem("DeviceName") + " found"}
                                            </center>
                                }
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} disabled={!selectedDevicetoDeselect.length > 0} onClick={handleDeselectDevices} fullWidth >Deselect</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} onClick={handleOpenAddMoreDevice} fullWidth >Add More</Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openAddMoreDevice}>
                    <CardHeader
                        title={currentStep === 1 ? `Select ${secureLocalStorage.getItem("DeviceName")}` : `${secureLocalStorage.getItem("DeviceName")} Configuration`}
                        action={
                            <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddMoreDevice}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        {currentStep === 1 && (
                            <>
                                {
                                    loading ? <CardHeader
                                        avatar={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton
                                                    variant="rounded"
                                                    sx={{
                                                        width: 80,
                                                        height: 80
                                                    }}
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 50 }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 50 }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 50 }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: 50 }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        } /> : devices.length > 0 ?
                                        <>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                maxHeight: 400,
                                                minWidth: 500,
                                                overflowY: "scroll"
                                            }} >
                                                {devices
                                                    .map((device) => (
                                                        <FormControlLabel
                                                            key={device.deviceuuid}
                                                            control={<Checkbox
                                                                checked={selectedDevicestoAssign.some(
                                                                    (d) => d.id === device.deviceuuid && d.macid === device.decmacid
                                                                )}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    const deviceId = device.deviceuuid;
                                                                    const deviceName = device.devicename;
                                                                    const deviceMacId = device.decmacid;
                                                                    const deviceimageName = device.deviceimageName;
                                                                    if (checked) {
                                                                        setSelectedDevicetoAssign([
                                                                            ...selectedDevicestoAssign,
                                                                            { id: deviceId, name: deviceName, macid: deviceMacId, image: deviceimageName },
                                                                        ]);
                                                                    } else {
                                                                        setSelectedDevicetoAssign((prevState) =>
                                                                            prevState.filter(
                                                                                (d) => d.id !== deviceId || d.macid !== deviceMacId
                                                                            )
                                                                        );
                                                                    }
                                                                    console.log(selectedDevicestoAssign);
                                                                }}
                                                                value={device.deviceuuid}
                                                            />}
                                                            label={<>
                                                                <CardHeader
                                                                    avatar={
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Avatar
                                                                                variant='rounded'
                                                                                alt={device.devicename}
                                                                                src={device.deviceimageName}
                                                                                sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                    <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                        {device.devicename}
                                                                                    </Typography>
                                                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                        {device.decmacid}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    } />

                                                            </>}
                                                        />
                                                    ))}
                                            </Box>
                                        </>
                                        : <center>
                                            No {secureLocalStorage.getItem("DeviceName")} Found
                                        </center>
                                }
                                <CardActions>
                                    <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleCloseAddMoreDevice} fullWidth >Cancel</Button>
                                    <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} disabled={!selectedDevicestoAssign.length > 0} onClick={handleNextStep} fullWidth >Next</Button>
                                </CardActions>
                            </>
                        )}

                        {currentStep === 2 && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        maxHeight: 400,
                                        minWidth: 500,
                                        overflowY: "scroll"
                                    }}
                                >
                                    {selectedDevicestoAssign.map((device) => (
                                        <Box
                                            key={device.id}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                my: 2
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Avatar
                                                    variant="rounded"
                                                    alt={device.name}
                                                    src={device.image}
                                                    sx={{ width: 80, height: 80, objectFit: "contain" }}
                                                />
                                                <Box sx={{ ml: 2 }}>
                                                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                                                        {device.name}
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                                        {device.macid}
                                                    </Typography>
                                                </Box>

                                                <FormControl sx={{ ml: 5 }} size="small" error={!selectedProducerType[device.id]}>
                                                    <InputLabel id={`demo-select-small-label-${device.id}`}>Select Producer Type</InputLabel>
                                                    <Select
                                                        labelId={`demo-select-small-label-${device.id}`}
                                                        id={`demo-select-small-${device.id}`}
                                                        label="Select Producer Type"
                                                        sx={{ width: 200 }} // Adjust the width here
                                                        value={device.producerType || ''}
                                                        onChange={(event) => handleChangeProducerType(event, device.id)}
                                                    >
                                                        <MenuItem value={0}>None</MenuItem>
                                                        <MenuItem value={1}>Zondan Producer</MenuItem>
                                                        <MenuItem value={2}>NK Producer</MenuItem>
                                                    </Select>
                                                    {!selectedProducerType[device.id] && <FormHelperText>Please select a producer type.</FormHelperText>}
                                                </FormControl>
                                            </Box>

                                        </Box>
                                    ))}
                                </Box>

                                <CardActions>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onMouseDown={handleMouseDown}
                                        onClick={handlePreviousStep}
                                        fullWidth
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onMouseDown={handleMouseDown}
                                        onClick={handleAssignDeviceToProducer}
                                        fullWidth
                                    >
                                        Submit
                                    </Button>
                                </CardActions>
                            </>
                        )}

                    </CardContent>
                </Dialog>
            </div>

        </div>
    );
}

export default ManageProducer;
