import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui-components
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import {
  AppBar,
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Slider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//externals
import { Icon } from '@iconify/react';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import 'react-image-gallery/styles/css/image-gallery.css';
import secureLocalStorage from 'react-secure-storage';
import styled from 'styled-components';

//services
import ApiCalls from "../../services/try";

//internals
import HighAlarm from "../../assets/audio/high_alarm.wav";
import LowAlarm from "../../assets/audio/low_alarm.wav";
import Iconify from '../../components/iconify/Iconify';
import LiveLineChart from "./LiveLineChart";
// import LiveLineChart from "./LiveChart";
// import MedicalDashboard from "./sample";

//-------------------------------------------------------------------------------------------

const Container = styled.div`
  background-color: #1F253D;
  min-height: 100vh;
  overflow-y: auto;  // Make the container scrollable
  max-height: calc(100vh - 65px - 100px);  // Adjust the maximum height, considering the height of ButtonBox
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

// Updated styles for the App bar
const StyledAppBar = styled(AppBar)`
  background-color: #1F253D;
  border-radius: 8;
  z-index: 1000; // Adjust the value as needed
`;


// Updated styles for the DeviceGroup and ChartItem
const DeviceGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;  // Adjusted alignment to start
  justify-content: center;
  background-color: #1F253D;
  padding: 20px;
  border-radius: 10;
  color: white;
  width: 100%;
  overflow: hidden;
  margin-top: ${(props) => (props.isTop ? '65px' : '5px')};
`;

const ChartItem = styled.div`
  border-radius: 12px;  // Remove border-radius
  padding: 10px;
  margin: 10px;
  color: white;
  width: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
`;

const ParameterItem = styled.div`
  flex: 4;
  margin: 5px;
  height: 190px;
  border-radius: 8px;
  padding: 10px;
  background-color: #394264;
  color: white;
  margin-bottom: 40px;  // Add margin at the bottom
`;

const StyledLabelDisplayItem = styled.div`
  flex: 1;
  margin: 5px;
  height: 190px;
  border-radius: 8px;
  padding: 10px;
  background-color: #394264;
  color: white;
  cursor: pointer;
  border: ${({ shouldBlink, borderColor }) =>
    shouldBlink ? `4px solid ${borderColor}` : '2px solid transparent'};
  transition: border 0.5s ease, background-color 0.3s ease; // Add smooth transitions

  &:hover {
    background-color: #506f9c; // Change the background color on hover
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
`;

const ActionButton = styled(Button)`
  margin-left: 5px;
  background-color: #dadada !important;  // Set button background color to light gray
  color: #394264 !important;  // Set text color to #1F253D

  &:focus, &:active {
    background-color: #dadada !important;  // Set the same background color as the default state
    color: #394264 !important;  // Set the same text color as the default state
  }
`;


const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;  // Center align vertically
  justify-content: center;  // Center align horizontally
  margin-top: 15px;  // Adjust the spacing between buttons and ParameterItem
  text-align: center;  // Align text to the center
`;

const ButtonBox = styled.div`
  height: 80px;
  border-radius: 8px;
  padding: 10px;
  background-color: #394264;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BottomRightStack = styled(Stack)`
  position: absolute;
  bottom: 15px;  // Adjust the bottom spacing as needed
  right: 30px;  // Adjust the right spacing as needed
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" sx={{ p: 3 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

//---------------------------------------------------------------------------------------------

const CMSView = () => {
  const highAlarmRef = useRef(new Audio(HighAlarm));
  const lowAlarmRef = useRef(new Audio(LowAlarm));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [apiStatus, setApiStatus] = useState(false);
  const [redirectToView, setRedirectToView] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimeRange, setShowTimeRange] = useState(false);
  const [openAlarmHistoryDialog, setOpenAlarmHistoryDialog] = useState(false);
  const [openlabelDisplayDialog, setOpenLabelDisplayDialog] = useState(false);
  const [isAlarmActive, setIsAlarmActive] = useState(false);

  const [alarmType, setAlarmType] = useState(null);
  const [cmsJSON, setCmsJSON] = useState(null);
  const [open, setOpen] = useState(null);
  const [selectedDate, handleDateChange] = React.useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [currentPatientId, setCurrentPatientId] = useState(null);
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  const [bedNo, setBedNo] = useState(null);

  const [page, setPage] = useState(0);
  const [paramTabVal, setParamTabVal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alarmBorder, setAlarmBorder] = useState("transparent");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const [paramValues, setParamValues] = useState({});

  const [medicalData, setMedicalData] = useState([]);
  const [alarmHistory, setAlarmHistory] = useState([]);

  const LabelDisplayItem = ({ paramValues, display }) => {
    const parameterId1 = paramValues.paramUUID;
    const parameterId2 = display?.LabelDisplay?.[0]?.ParamID;
    const paramName = paramValues.paramName;
    const paramValue = paramValues.value;
    const alarmBorderColor = paramValues.alarmBorderColor;
    const alarmType = paramValues.alarmType;
    setAlarmBorder(alarmBorderColor);

    console.log(alarmBorderColor + "****" + alarmType);
    if (alarmBorderColor != "transparent" && alarmBorderColor != undefined && alarmType != " " && alarmType != undefined) {
      saveAlarmDetails(alarmType, paramValue, parameterId1, paramName);

    }

    const shouldBlink = parameterId1 === parameterId2;

    return (
      <StyledLabelDisplayItem
        className="label-box"
        shouldBlink={shouldBlink}
        borderColor={alarmBorderColor}
        onClick={() => handleOpenLabelDisplayDialog(display)}
      >
        {display.LabelDisplay ? (
          display.LabelDisplay.map((label, labelIndex) => (
            <div key={labelIndex}>
              <Typography sx={{
                color: shouldBlink && alarmBorderColor != "transparent" ? alarmBorderColor : 'white',
              }} variant="subtitle2">{label.ParamName}:</Typography>
              <Typography
                variant="h1"
                sx={{
                  color: shouldBlink && alarmBorderColor != "transparent" ? alarmBorderColor : 'rgba(75,192,192,1)',
                  textAlign: "center",
                }}
              >
                {shouldBlink ? paramValue : "-?-"}
              </Typography>
            </div>
          ))
        ) : (
          <div>
            <Typography variant="subtitle2">-?-</Typography>
          </div>
        )}

      </StyledLabelDisplayItem>
    );
  };

  useEffect(() => {
    var cmsId = secureLocalStorage.getItem('CMSToView');
    const getCMSJson = async () => {
      ApiCalls.getdetails(`cmsId/${cmsId}/getcmsjson`)
        .then((response) => {
          console.log(response.data);
          if (response.data !== undefined) {
            setCmsJSON(response.data);
            setApiStatus(true);

            // Extract information from the first bed
            const firstBed = response.data.BEDS && response.data.BEDS.length > 0 ? response.data.BEDS[0] : null;
            const firstBedNo = firstBed ? firstBed.BedNo : null;
            const firstDeviceId = firstBed ? firstBed.Display[0].DeviceID : null;
            const firstPatientId = firstBed && firstBed.Display && firstBed.Display.length > 0
              ? firstBed.PatientID
              : null;
            console.log("***firstBedNo***" + firstBedNo);
            console.log("***firstDeviceId***" + firstDeviceId);
            console.log("***firstPatientId***" + firstPatientId);

            // Save information to state
            setBedNo(firstBedNo);
            setCurrentDeviceId(firstDeviceId);
            setCurrentPatientId(firstPatientId);
          } else {
            setCmsJSON([]);
            setApiStatus(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setCmsJSON([]);
          setApiStatus(false);
        });
    };

    getCMSJson();
  }, []);

  // Group the beds based on device name
  const groupedBeds = {};
  if (cmsJSON && cmsJSON.BEDS) {
    cmsJSON.BEDS.forEach((bed) => {
      const deviceName = bed.Display[0].DevName;
      if (!groupedBeds[deviceName]) {
        groupedBeds[deviceName] = {
          beds: [],
          deviceName: deviceName,
        };
      }
      groupedBeds[deviceName].beds.push(bed);
    });
  }

  const handleCMSClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleExitCMSView = () => {
    setRedirectToView(true);
  };

  const handleSettingsClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpen(null);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  const handlePlayPauseToggle = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    // Add additional logic here if needed when play/pause state changes
  };

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);

    switch (buttonType) {
      case "daily":
        setShowDatePicker(!showDatePicker);
        setShowTimeRange(false); // Close the time range popover
        break;

      case "hourly":
        setShowDatePicker(false); // Close the date picker popover
        setShowTimeRange(false); // Close the time range popover
        break;

      case "timeRange":
        setShowDatePicker(false); // Close the date picker popover
        setShowTimeRange(!showTimeRange);
        break;

      default:
        break;
    }
  };


  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  }

  const handleCloseTimeRange = () => {
    setShowTimeRange(false);
  }

  const saveAlarmDetails = (alarmType, paramValue, parameterId, paramName) => {
    console.log("**********************");
    console.log(alarmType, paramValue, parameterId, paramName);
    console.log("**********************");
    console.log(currentPatientId, currentDeviceId, bedNo);

    if (alarmType === "high") {
      setAlarmType("high");
      setIsAlarmActive(true);
      highAlarmRef.current.play();
    } else if (alarmType === "low") {
      setAlarmType("low");
      setIsAlarmActive(true);
      lowAlarmRef.current.play();
    }

    const data = {
      patientId: currentPatientId,
      bedNo: bedNo,
      paramId: parameterId,
      paramName: paramName,
      devMacId: currentDeviceId,
      alarmType: alarmType,
      value: paramValue
    }

    ApiCalls.register("saveAlarmDetails", data)
      .then((response) => {
        console.log(response.data);
      }).catch((err) => {
        console.log(err);
      })

  }

  const handleOpenAlarmHistoryDialog = () => {
    setOpenAlarmHistoryDialog(true);
    console.log("currentPatientId: " + currentPatientId);
    ApiCalls.getdetails("patient/" + currentPatientId + "/alarmHistory")
      .then((response) => {
        console.log(response.data);
        if (response.data.alarms != undefined) {
          // Format generatedTime in alarmHistory
          const formattedAlarmHistory = response.data.alarms.map(alarm => ({
            ...alarm,
            generatedTime: moment.utc(parseInt(alarm.generatedTime)).local().format("MMM-DD, YYYY hh:mm A"),
          }));

          setAlarmHistory(formattedAlarmHistory);
        }
      }).catch((err) => {
        console.log(err);
        setAlarmHistory([]);
      });
  };


  const handleCloseAlarmHistoryDialog = () => {
    setOpenAlarmHistoryDialog(false);
  };

  const handleOpenLabelDisplayDialog = (display) => {
    setSelectedButton('labelDisplay');
    console.log(display);

    display.LabelDisplay?.map((label, index) => {
      console.log(label.ParamName);
      setParamTabVal(label.ParamName);
    })

    setOpenLabelDisplayDialog(true);
  };

  const handleCloseLabelDisplayDialog = () => {
    setOpenLabelDisplayDialog(false);
  };

  const handlePauseAllAlerts = () => {
  }

  const handleStartTimeChange = (newTime) => {
    setStartTime(newTime);
    // Your additional logic with the selected start time
  };

  const handleEndTimeChange = (newTime) => {
    setEndTime(newTime);
    // Your additional logic with the selected end time
  };


  const handleChangeParamTab = (event, newValue) => {
    console.log(newValue);
    setParamTabVal(newValue);
  };

  if (redirectToView) {
    return <Redirect to={'/cms'} />;
  }


  return (
    <Container>
      <StyledAppBar position="absolute">
        <Toolbar>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {/* <b>{secureLocalStorage.getItem('cmsName').toUpperCase()}</b> */}
          </Typography>

          <IconButton onClick={handleCMSClick} onMouseDown={handleMouseDown}>
            <Icon icon={'solar:monitor-smartphone-bold'} width="40" color="#ECF0F1" />
          </IconButton>
        </Toolbar>
      </StyledAppBar>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleSettingsClick} sx={{ m: 1 }}>
          <ListItemIcon>
            <Iconify icon={'eva:settings-2-fill'} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleExitCMSView} sx={{ m: 1 }}>
          <ListItemIcon>
            <Iconify icon={'majesticons:logout'} />
          </ListItemIcon>
          Exit
        </MenuItem>
      </Popover>

      <Dialog
        maxWidth="sm"
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(31, 37, 61, 0.8)', // Adjust the color and opacity as needed
          },
        }}>
        <DialogTitle>Customize Grid</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onMouseDown={handleMouseDown}
            onClick={handleCloseDialog}
            sx={{
              color: "white"
            }}
          >
            Cancel
          </Button>
          <Button
            onMouseDown={handleMouseDown}
            sx={{
              color: "white"
            }}
          >
            Apply</Button>
        </DialogActions>
      </Dialog>

      {Object.keys(groupedBeds).map((deviceName, index) => (
        <DeviceGroup key={index} isTop={index === 0}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginLeft: 5, marginRight: 5 }}
          >
            {/* Left side: Device details */}
            <Grid item>
              <Stack sx={{ flexDirection: 'row' }}>
                <Typography variant="subtitle1" component="div" sx={{ marginRight: 5 }}>
                  <b>Device Name: {deviceName}</b>
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ marginRight: 5 }}>
                  <b>Patient Name: {groupedBeds[deviceName].beds[0].PatientName}</b>
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ marginRight: 5 }}>
                  <b>Ward No: {groupedBeds[deviceName].beds[0].WardNo}</b>
                </Typography>
                <Typography variant="subtitle1" component="div">
                  <b>Bed No: {groupedBeds[deviceName].beds[0].BedNo}</b>
                </Typography>
              </Stack>
            </Grid>

            {/* Right side: Buttons */}
            <Grid item sx={{ marginRight: 6 }}>
              <ButtonContainer>
                <ActionButton
                  id="dailyButton"
                  onClick={() => handleButtonClick('daily')}
                  variant={selectedButton === 'daily' ? 'outlined' : 'contained'}
                  onMouseDown={handleMouseDown}
                  color="primary"
                  endIcon={<Icon icon={'lets-icons:date-range-light'} width="30" />}
                  startIcon={selectedButton === 'daily' && <Icon icon={'icomoon-free:radio-checked'} />}
                >
                  Daily
                </ActionButton>

                <Popover
                  open={Boolean(showDatePicker)}
                  anchorEl={document.getElementById('dailyButton')} // Replace 'dailyButton' with the actual id of your button
                  onClose={handleCloseDatePicker}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      mt: 1.5,
                      ml: 0.75,
                      width: 180,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <TextField
                    type='date' />
                </Popover>

                <ActionButton
                  onClick={() => handleButtonClick('hourly')}
                  onMouseDown={handleMouseDown}
                  variant={selectedButton === 'hourly' ? 'outlined' : 'contained'}
                  color="secondary"
                  endIcon={<Icon icon={'bx:timer'} width="30" />}
                  startIcon={selectedButton === 'hourly' && <Icon icon={'icomoon-free:radio-checked'} />}
                >
                  Hourly
                </ActionButton>

                <ActionButton
                  id="timeRange"
                  onClick={() => handleButtonClick('timeRange')}
                  onMouseDown={handleMouseDown}
                  variant={selectedButton === 'timeRange' ? 'outlined' : 'contained'}
                  color="info"
                  endIcon={<Icon icon={'uil:slider-h-range'} width="30" />}
                  startIcon={selectedButton === 'timeRange' && <Icon icon={'icomoon-free:radio-checked'} />}
                >
                  Time Range
                </ActionButton>
              </ButtonContainer>

              <Popover
                open={Boolean(showTimeRange)}
                anchorEl={document.getElementById('timeRange')} // Replace 'dailyButton' with the actual id of your button
                onClose={handleCloseTimeRange}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div>
                    <DesktopTimePicker
                      label="Start Time"
                      value={startTime}
                      onChange={(newTime) => handleStartTimeChange(newTime)}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                    <DesktopTimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newTime) => handleEndTimeChange(newTime)}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                  </div>
                  <div>
                    <MobileTimePicker
                      label="Start Time"
                      value={startTime}
                      onChange={(newTime) => handleStartTimeChange(newTime)}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                    <MobileTimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newTime) => handleEndTimeChange(newTime)}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                  </div>
                </LocalizationProvider>
                {/* Popover content */}
              </Popover>
            </Grid>
          </Grid>

          <ChartItem>
            <div className="box-container">
              {Object.keys(groupedBeds).map((deviceName, deviceIndex) => (
                <div key={deviceIndex} className="box">
                  {groupedBeds[deviceName].beds.map((bed, bedIndex) => (
                    <div key={bedIndex}>
                      {bed.Display.map((display, displayIndex) => (
                        <Stack key={displayIndex} sx={{ flexDirection: 'row' }}>
                          <ParameterItem className="parameter-box">
                            <Typography variant="subtitle2">{display.ParamName}</Typography>
                            <LiveLineChart
                              currentPatientId={currentPatientId}
                              parameterName={display.ParamName}
                              parameterId={display.ParamID}
                              deviceId={display.DeviceID} // Pass DeviceID as prop
                              onParamValueChange={(updatedValues) => {
                                setParamValues((prevValues) => ({ ...prevValues, ...updatedValues }));
                              }}
                            />
                            <ButtonGroup>
                              <IconButton onMouseDown={handleMouseDown} color="secondary">
                                <Icon icon={'fluent-emoji-high-contrast:fast-forward-button'} color="#ECF0F1" hFlip={true} />
                              </IconButton>
                              <IconButton onClick={handlePlayPauseToggle} onMouseDown={handleMouseDown} color="secondary">
                                {isPlaying ? <Icon icon={'fluent-emoji-high-contrast:play-button'} color="#ECF0F1" /> : <Icon icon={'fluent-emoji-high-contrast:pause-button'} color="#ECF0F1" />}
                              </IconButton>
                              <IconButton onMouseDown={handleMouseDown} color="secondary">
                                <Icon icon={'fluent-emoji-high-contrast:fast-forward-button'} color="#ECF0F1" />
                              </IconButton>
                            </ButtonGroup>
                          </ParameterItem>
                          <LabelDisplayItem paramValues={paramValues} display={display} />
                        </Stack>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </ChartItem>

        </DeviceGroup>
      ))}

      <BottomRightStack direction="row" spacing={2}>

        {/* <ButtonBox>
          <Button onClick={handlePauseAllAlerts} onMouseDown={handleMouseDown}>
            <Icon icon={'mingcute:user-info-line'} width="40" color="white" />
            <Typography variant="h6" sx={{ color: "white" }}>
              {secureLocalStorage.getItem("PatientName")} Details
            </Typography>
          </Button>
        </ButtonBox> */}

        <ButtonBox>
          <Button onClick={handlePauseAllAlerts} onMouseDown={handleMouseDown}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 448 512">
              <path fill="#FFF" d="M439.9,362.7l-7.4,-8.3c-31.2,-35.2 -48.5,-80.5 -48.5,-127.6V208c0,-77.4 -55,-142 -128,-156.8V32c0,-17.7 -14.3,-32 -32,-32s-32,14.3 -32,32v19.2C119,66 64,130.6 64,208v18.8c0,47 -17.3,92.4 -48.5,127.6l-7.4,8.3c-8.4,9.4 -10.4,22.9 -5.3,34.4C7.9,408.6 19.4,416 32,416h384c12.6,0 24,-7.4 29.2,-18.9S448.3,372.1 439.9,362.7zM337.5,369.4c-9.4,9.4 -24.7,9.4 -34.1,0L224,290.1l-79.3,79.4c-9.4,9.4 -24.7,9.4 -34.1,0c-9.4,-9.4 -9.4,-24.7 0,-34.1l79.3,-79.4l-79.4,-79.3c-9.4,-9.4 -9.4,-24.7 0,-34.1c9.4,-9.4 24.7,-9.4 34.1,0l79.4,79.3l79.3,-79.4c9.4,-9.4 24.7,-9.4 34.1,0c9.4,9.4 9.4,24.7 0,34.1L258.1,256l79.4,79.3C346.9,344.7 346.9,360 337.5,369.4z" />
              <path fill="#FFF" d="M160,448c0,17 6.7,33.3 18.7,45.3S207,512 224,512s33.3,-6.7 45.3,-18.7S288,465 288,448h-64H160z" />
            </svg>
            <Typography variant="h6" sx={{ color: "white" }}>
              Pause Alarms
            </Typography>
          </Button>
        </ButtonBox>

        <ButtonBox>
          <Button onClick={handleOpenAlarmHistoryDialog} onMouseDown={handleMouseDown}>
            <Icon icon={'fluent:person-alert-16-regular'} width="50" color="white" />
            <Typography variant="h6" sx={{
              color: "white"
            }}>
              Alarm History
            </Typography>
          </Button>
        </ButtonBox>

      </BottomRightStack>

      <Dialog
        open={openAlarmHistoryDialog}
        onClose={handleCloseAlarmHistoryDialog}
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(31, 37, 61, 0.8)',
          },
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>Alarm History</DialogTitle>
        <DialogContent>
          <MDBDataTable
            borderless
            fullPagination
            entries={5}
            entriesOptions={[5, 10, 25, 50, 100]}
            noBottomColumns
            responsive
            theadColor="rgba(31, 37, 61, 0.8)"
            theadTextWhite
            tbodyColor="rgba(31, 37, 61, 0.8)"
            tbodyTextWhite
            tbodyHoverColor="rgba(31, 37, 61, 1)"
            pagingTop
            striped
            sortable={false}
            style={{
              thead: {
                color: 'white', // Table head text color
              },
              tbody: {
                color: 'white', // Table body text color
              },
              pagination: {
                color: 'white', // Pagination buttons color
              },
              entries: {
                color: 'white', // "Show entries" text color
              },
            }}
            data={{
              columns: [
                {
                  label: 'Parameter Name',
                  field: 'paramName',
                  sort: 'asc',
                  width: 150,
                },
                {
                  label: 'Value',
                  field: 'value',
                  sort: 'asc',
                  width: 150,
                },
                {
                  label: 'Alarm Type',
                  field: 'alarmType',
                  sort: 'asc',
                  width: 150,
                },
                {
                  label: 'Event Time',
                  field: 'generatedTime',
                  sort: 'asc',
                  width: 150,
                },
              ],
              rows: alarmHistory.map((alarm, index) => ({
                paramName: alarm.paramName,
                value: alarm.value,
                alarmType: (
                  <span
                    style={{
                      color: alarm.alarmType === 'low' ? 'yellow' : "#FF0000",
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      textTransform: 'uppercase',
                    }}
                  >
                    {alarm.alarmType}
                  </span>
                ),
                generatedTime: alarm.generatedTime,
              })),
              pagination: true,
              textWhite: true, // Make page number buttons and "Next" button white
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onMouseDown={handleMouseDown} onClick={handleCloseAlarmHistoryDialog} sx={{ color: 'white' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openlabelDisplayDialog}
        onClose={handleCloseLabelDisplayDialog}
        maxWidth="xl"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(31, 37, 61, 0.8)',
            borderRadius: '8px'
          },
        }}
      >
        <Card sx={{
          minWidth: 800,
          backgroundColor: 'rgba(31, 37, 61, 0.8)', // Set the same color as the Dialog
        }}>
          <CardHeader
            title={
              <Tabs
                value={paramTabVal}
                onChange={handleChangeParamTab}
                onMouseDown={handleMouseDown}
                aria-label="icon label tabs example"
                sx={{
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  display: "flex",
                }}
              >
                {Object.keys(groupedBeds).map((deviceName, deviceIndex) =>
                  groupedBeds[deviceName].beds.map((bed, bedIndex) =>
                    bed.Display?.map((display, displayIndex) =>
                      display.LabelDisplay?.map((label, labelIndex) => (
                        <Tab
                          key={labelIndex}
                          label={label.ParamName}
                          sx={{
                            fontSize: 18,
                            color: "white",
                            minWidth: 'auto',
                            "&.Mui-selected": {
                              color: "#2596be",
                            },
                            "& .MuiTabs-indicator": {
                              backgroundColor: "#2596be",
                            },
                          }}
                          value={label.ParamName}
                        />

                      ))
                    )
                  )
                )}
              </Tabs>
            }
            action={
              <IconButton onMouseDown={handleMouseDown} onClick={handleCloseLabelDisplayDialog}>
                <Icon icon="fa:close" color="white" />
              </IconButton>
            }
            sx={{ backgroundColor: 'rgba(31, 37, 61, 0.8)' }}
          />

        </Card>
        <DialogContent>
          {Object.keys(groupedBeds).map((deviceName, deviceIndex) => (
            <div key={deviceIndex}>
              {groupedBeds[deviceName].beds.map((bed, bedIndex) => (
                <div key={bedIndex}>
                  {bed.Display?.map((display, displayIndex) => (
                    <div key={displayIndex}>
                      {display.LabelDisplay?.map((label, labelIndex) => (
                        <TabPanel key={labelIndex} value={paramTabVal} index={label.ParamName}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography variant='subtitle2' sx={{ color: 'white' }}>{"High " + paramTabVal}</Typography>
                              <Chip sx={{
                                marginTop: 2,
                                height: 40,
                                width: 100,
                                fontSize: 25,
                                fontWeight: "bold",
                                color: "white",
                              }} label={label.HighValue} />
                              <IconButton onMouseDown={handleMouseDown} sx={{
                                marginTop: 2,
                              }}>
                                <Icon icon="icon-park-solid:up-c" color="#2596be" width="40" />
                              </IconButton>
                              <IconButton onMouseDown={handleMouseDown} sx={{
                                marginTop: 2,
                              }}>
                                <Icon icon="icon-park-solid:down-c" color="#2596be" width="40" />
                              </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant='subtitle2' sx={{ color: 'white' }}>{"Low " + paramTabVal}</Typography>
                              <Chip sx={{
                                marginTop: 2,
                                height: 40,
                                width: 100,
                                fontSize: 25,
                                fontWeight: "bold",
                                color: "white",
                              }} label={label.LowValue} />

                              <IconButton onMouseDown={handleMouseDown} sx={{
                                marginTop: 2,
                              }}>
                                <Icon icon="icon-park-solid:up-c" color="#2596be" width="40" />
                              </IconButton>
                              <IconButton onMouseDown={handleMouseDown} sx={{
                                marginTop: 2,
                              }}>
                                <Icon icon="icon-park-solid:down-c" color="#2596be" width="40" />
                              </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant='subtitle2' sx={{ color: 'white' }}>{paramTabVal + " Volume"}</Typography>
                              <Slider
                                defaultValue={50}
                                sx={{
                                  marginTop: 2,
                                  color: 'white',
                                  '& .MuiSlider-thumb': {
                                    width: 16,
                                    height: 16,
                                  },
                                }}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                              />

                            </Grid>
                          </Grid>
                        </TabPanel>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </DialogContent>
      </Dialog>

    </Container >
  );
};

export default CMSView;