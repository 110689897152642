import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const url = "https://www.iorbit-tech.com:8443/api/v1/";
// const url = "http://localhost:8085/api/v1/";
// const url = "http://206.189.141.109:8085/api/v2/"
// const url = "http://178.128.165.237:8085/api/v1/";
const url1 = "https://dev.iorbit.health:6443/api/v2/";

const host = window.location.hostname;
let port = parseInt(window.location.port);
port = port + 10;

var s = secureLocalStorage.getItem("sessionId");

class Apicalls {
  login(path, data) {
    // console.log("axios.post(" + url + path, data + ")");
    return axios.post(url + path, data);
  }
  getRoleList() {
    return axios.get(`${url}ithings/getRolelist`, {
      headers: { Authorization: s },
    });
  }
  getSelectedRoleList(id) {
    return axios.get(`${url}ithings/` + id + `/getSelectedRolelist`, {
      headers: { Authorization: s },
    });
  }
  insertSelectedRoles(data) {
    return axios.post(`${url}ithings/insertSelectedRoles`, data, {
      headers: { Authorization: s },
    });
  }
  getMasterMenuList(data) {
    return axios.get(`${url}ithings/` + data + `/getmastermenulist`, {
      headers: { Authorization: s },
    });
  }

  insertRoleBasedMenu(data, user, roleName) {
    return axios.post(
      `${url}ithings/` + user + `/` + roleName + `/insertRoleBasedMenu`,
      data,
      { headers: { Authorization: s } }
    );
  }
  getRoleBasedMenu(id, roleName) {
    // console.log(`${url}ithings/` + id + `/getRoleBasedMenu/` + roleName);
    return axios.get(`${url}ithings/` + id + `/getRoleBasedMenu/` + roleName, {
      headers: { Authorization: s },
    });
  }
  getCustomMenuList(data) {
    return axios.get(`${url}ithings/` + data + `/getcustommenulist`, {
      headers: { Authorization: s },
    });
  }
  checkMenuExist(user) {
    return axios.get(`${url}ithings/` + user + `/checkmenuExist`, {
      headers: { Authorization: s },
    });
  }
  insertselectedmenu_noicon(files) {
    return axios.post(`${url}ithings/insertselectedmenu_noicon`, files, {
      headers: { Authorization: s },
    });
  }
  insertselectedmenu(files) {
    return axios.post(`${url}ithings/insertselectedmenu`, files, {
      headers: { Authorization: s },
    });
  }
  insertCustomMenu(data) {
    // console.log("axios.post(" + url + "ithings/insertCustomMenu", data);
    return axios.post(url + "ithings/insertCustomMenu", data, {
      headers: { Authorization: s },
    });
  }
  insertCustomMenuandIcon(files) {
    return axios.post(`${url}ithings/insertCustomMenuandIcon`, files, {
      headers: { Authorization: s },
    });
  }
  getdetails(path) {
    // console.log("Path =" + url + path);
    try {
      return axios.get(url + path, { headers: { Authorization: s } });
    } catch (ex) {
      // console.log("Error For retrieving=" + ex);
    }
  }
  register(path, data) {
    if (s == null) {
      s =
        "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoibHVrZUBtYWlsaW5hdG9yLmNvbSIsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJpYXQiOjE2MTk4MjIwMDl9.aVNO_a3_4oahr5MJVwEaFZSvtp0orqCwR8FRpnjOu464FZ_2pM0KP5drkkLeqNfsgiUj9Yf14xyYOkTIVXd0Rg";
    }
    // console.log("axios.post(" + url + path, data);
    return axios.post(url + path, data, { headers: { Authorization: s } });
  }
  storeFile(path, data) {
    if (s == null) {
      s =
        "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoibHVrZUBtYWlsaW5hdG9yLmNvbSIsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJpYXQiOjE2MTk4MjIwMDl9.aVNO_a3_4oahr5MJVwEaFZSvtp0orqCwR8FRpnjOu464FZ_2pM0KP5drkkLeqNfsgiUj9Yf14xyYOkTIVXd0Rg";
    }
    // console.log("axios.post(" + url + path, data);
    return axios.post(url + path, data, {
      headers: { "Content-Type": "multipart/form-data", Authorization: s },
    });
  }
  registerwithoutJWT(path, data) {
    var sid =
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoibHVrZUBtYWlsaW5hdG9yLmNvbSIsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJpYXQiOjE2MTk4MjIwMDl9.aVNO_a3_4oahr5MJVwEaFZSvtp0orqCwR8FRpnjOu464FZ_2pM0KP5drkkLeqNfsgiUj9Yf14xyYOkTIVXd0Rg";
    // console.log("axios.post(" + url + path, data);
    return axios.post(url + path, data, { headers: { Authorization: sid } });
  }
  registerwithoutAuthorization(path) {
    if (s == "") {
      s =
        "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoibHVrZUBtYWlsaW5hdG9yLmNvbSIsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJpYXQiOjE2MTk4MjIwMDl9.aVNO_a3_4oahr5MJVwEaFZSvtp0orqCwR8FRpnjOu464FZ_2pM0KP5drkkLeqNfsgiUj9Yf14xyYOkTIVXd0Rg";
    }
    return axios.post(url + path, { headers: { Authorization: "" } });
  }
  edit(path, data) {
    // console.log("axios.put(" + url + path, data + ")");
    return axios.put(url + path, data, { headers: { Authorization: s } });
  }
  userdetails(path) {
    return axios.get(url + path, { headers: { Authorization: s } });
  }
  delete(path) {
    // console.log("axios.delete(" + url + path + "}},");
    try {
      return axios.delete(url + path, { headers: { Authorization: s } });
    } catch (e) {
      // console.log("APICALL Error =" + e);
    }
  }

  getEmbeddedData(path) {
    try {
      return axios.get(url1 + path, {
        headers: { Authorization: s },
      });
    } catch (ex) {}
  }

  getCustomFieldList(userId) {
    return axios.get(`${url}ithings/` + userId + `/getCustomFieldList`, {
      headers: { Authorization: s },
    });
  }
}

export default new Apicalls();
