import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Delete, EdgesensorHighTwoTone, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//internals

//externals
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------

const ManageSensor = () => {

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const [sensors, setSensors] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const loadSensorList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("tenantid/" + tenantId + "/listallsensors")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor != undefined) {
                    setSensors(response.data.sensor);
                    setLoading(false)
                } else {
                    setSensors([]);
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                setSensors([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        loadSensorList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const filteredSensors = sensors.filter((sensor) =>
        sensor.sensormacid.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDeleteSensor = (sensorId) => {
        console.log(sensorId)
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    ApiCalls.delete("sensor/" + sensorId + "/deletesensor")
                        .then((response) => {
                            console.log(response.data);
                            if (response.data.status.message === "Success") {
                                swal("Deleted Successfuly!", {
                                    icon: "success",
                                });
                                loadSensorList();
                            }
                        })
                }
            });
    }

    const handleEditSensor = (sensor) => {
        console.log(sensor.sensoruuId);
        secureLocalStorage.setItem("sensorId", sensor.sensoruuId)
        setRedirectToEdit(true);
    }

    const handleScanButtonClick = () => {
        // Open the popup when the "Scan" button is clicked
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopup(false);
    };

    if (redirectToEdit) {
        return (<Redirect to={'/editsensor'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registersensor'} />)
    }


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label="Manage Sensor" />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureLocalStorage.getItem("rolename") === "Tenant" ? "" : "none"
                    }}
                        variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setRedirectToForm(true)}>
                        New Sensor
                    </Button>
                </Stack>


                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            Sensor List
                        </Typography>}
                        action={
                            <TextField
                                label="Search Sensor"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredSensors.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredSensors.map((sensor) => (
                                        <Grid item xs={12} sm={4} md={4} key={sensor.sensoruuId}>
                                            <Card sx={{
                                                maxWidth: 300,
                                                boxShadow: 3,
                                                '&:hover': {
                                                    border: '2px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                }
                                            }}>
                                                <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={sensor.sensorName} />
                                                <Avatar
                                                    sx={{
                                                        bgcolor: blue[900],
                                                        width: 120, // Adjust the width as needed
                                                        height: 120, // Adjust the height as needed
                                                        margin: 'auto',
                                                        marginLeft: '80px',// Center the avatar
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 2,
                                                    }}
                                                    src={sensor.sensorImg}
                                                    aria-label="recipe"
                                                >
                                                    {sensor.sensorImg === "" ? <EdgesensorHighTwoTone sx={{
                                                        fontSize: 60
                                                    }} /> : sensor.sensorImg}
                                                </Avatar>
                                                <CardContent>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        Sensor Mac Id : {sensor.sensormacid}
                                                    </Typography>
                                                    <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                        Sensor Type : {sensor.sensorType}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditSensor(sensor)} startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteSensor(sensor.sensoruuId)} startIcon={<Delete />}>Delete</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No Sensor Found
                                </center>
                            )
                        }

                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default ManageSensor
