import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//internals
import Preview from "../../layout/Preview";
import DeviceProfile from "./DeviceProfile";
import DeviceProfileReview from './DeviceProfileReview';
import ParameterGroupList from "./ParameterGroupList";
import ParameterList from "./ParameterList";

//mui-components
import { Home } from '@mui/icons-material';
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    Stack,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Typography,
    stepConnectorClasses
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//externals
import secureLocalStorage from 'react-secure-storage';

//components
import { Icon } from '@iconify/react';
import Iconify from "../../components/iconify";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <Iconify icon={'fluent-mdl2:auto-deploy-settings'} />,
        2: <Iconify icon={'fluent:multiselect-rtl-24-filled'} />,
        3: <Iconify icon={'fluent:group-list-24-filled'} />,
        4: <Iconify icon={'tabler:device-ipad-cog'} />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

//-----------------------------------------------------------------------------

const AddDeviceProfile = () => {
    const steps = ['Profile', 'Parameters', 'Grouping', 'Review'];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    useEffect(() => {
        // Update activeStep if it exceeds the valid step range
        if (activeStep >= steps.length) {
            setActiveStep(steps.length - 1);
        }
    }, [activeStep, steps.length]);

    const [formData, setFormData] = useState({
        modelName: "",
        modelNumber: "",
        versionNumber: "",
        modelmanufacturer: "",
        modelTags: "",
        devImage: "",
        groupName: "",
        type: "",
        tags: "",
        rulename: "",
        qrEnabled: false
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleQREnabled = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setFormData({
                ...formData,
                qrEnabled: true,
            });
        } else {
            setFormData({
                ...formData,
                qrEnabled: false,
            });
        }

    };

    const onChangeX = (e) => {
        const files = e.target.files;
        const file = files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setFormData({
                ...formData,
                devImage: reader.result
            })
        };
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(step) {
        console.log('activeStep:', activeStep);
        console.log('step:', step);
        switch (step) {
            case 0:
                return <DeviceProfile
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeStep={activeStep}
                    steps={steps}
                    data={formData}
                    handleChange={handleChange}
                    handleQREnabled={handleQREnabled}
                    onChangeX={onChangeX}
                />;
            case 1:
                return <ParameterList
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeStep={activeStep}
                    steps={steps}
                    data={formData}
                    handleChange={handleChange}
                />;
            case 2:
                return <ParameterGroupList
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeStep={activeStep}
                    steps={steps}
                    data={formData}
                    handleChange={handleChange}
                />;
            case 3:
                return <DeviceProfileReview
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleReset={handleReset}
                    activeStep={activeStep}
                    steps={steps}
                    data={formData}
                />;
            default:
                return "Unknown Step";
        }
    }

    const handleReset = () => {
        secureLocalStorage.removeItem('currentDeviceID');
        secureLocalStorage.removeItem('checkedItems');
        secureLocalStorage.removeItem('currentDeviceName');
        secureLocalStorage.removeItem('editDeviceId');
        secureLocalStorage.removeItem('selectedParameters');
        setRedirectToReferrer(true);
        setActiveStep(0);
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/deviceprofile">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-pause'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Manage Device Profile
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Add Device Profile" />
                    </Breadcrumbs>
                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            Add Device Profile
                        </Typography>}
                    />
                    <CardContent>
                        <Stack sx={{ width: '100%' }} spacing={4}>
                            <Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel className="font-weight-bold text-muted" StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>

                        <div>
                            <Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs></Grid>
                                    <Grid item xs>
                                        {getStepContent(activeStep)}
                                    </Grid>
                                    <Grid item xs></Grid>
                                </Grid>
                            </Typography>
                            <div />
                        </div>
                    </CardContent>
                </Card>

            </Container>

        </div>
    )
}

export default AddDeviceProfile
